<template>
<div>
    <h2>{{ titulo }}</h2>
    <div class="container-opciones">
        <v-checkbox v-for="(item,index) in opciones" :key="index" v-model="opcionesSeleccionadas" :label="item" :value="item" hide-details color="success" @change="$emit('change', opcionesSeleccionadas)"></v-checkbox>
    </div>
    <span class="mt-5">Otro/Amplie:</span>
    <br>
    <input class="otro" type="text">
</div>
</template>

<script>
export default {
    props: ['titulo', 'opciones', 'seleccionadas'],
    data: () => ({
        opcionesSeleccionadas: [],
    }),
    created() {
        this.opcionesSeleccionadas = this.seleccionadas ?? [];
        if(typeof this.opcionesSeleccionadas === 'string') {
            this.opcionesSeleccionadas = [this.opcionesSeleccionadas];
        }
    }
}
</script>

<style lang="scss" scoped>
.container-opciones {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1rem;

    .v-input {
        width: 45%;
    }
}

.otro {
    border-bottom: black solid 1px;
    width: 100%;
}
</style>
