<template>
<v-app :style="{ background: '#efefef' }">
    <div class="container-mediciones">
        <h1>Signos vitales</h1>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>Hora</h2>
            <div class="medicion">
                <div class="hora" @click="setHora('hora1')">
                    {{ hora1 }}
                </div>
                <div class="hora" @click="setHora('hora2')">
                    {{ hora2 }}
                </div>
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>T.A mm Hg</h2>
            <div class="medicion">
                <input type="text" v-model="ta1" @input="update($event, 'ta1')">
                <input type="text" v-model="ta2" @input="update($event, 'ta2')">
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>PULSO X'</h2>
            <div class="medicion">
                <input type="text" v-model="pulso1" @input="update($event, 'pulso1')">
                <input type="text" v-model="pulso2" @input="update($event, 'pulso2')">
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>TEMP C°</h2>
            <div class="medicion no-gap">
                <input type="text" v-model="temperatura1" @input="update($event, 'temperatura1')">
                <span>°</span>
                <input type="text" v-model="temperatura2" @input="update($event, 'temperatura2')">
                <span>°</span>
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>RESP.X'</h2>
            <div class="medicion">
                <input type="text" v-model="resp1" @input="update($event, 'resp1')">
                <input type="text" v-model="resp2" @input="update($event, 'resp2')">
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>SAT.O <sup>2</sup></h2>
            <div class="medicion no-gap">
                <input type="text" v-model="sat1" @input="update($event, 'sat1')">
                <span>%</span>
                <input type="text" v-model="sat2" @input="update($event, 'sat2')">
                <span>%</span>
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>GLUCOMETRÍA</h2>
            <div class="medicion">
                <input type="text" v-model="glucometria1" @input="update($event, 'glucometria1')">
                <input type="text" v-model="glucometria2" @input="update($event, 'glucometria2')">
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>PESO</h2>
            <div class="medicion no-gap">
                <input type="text" v-model="peso1" @input="update($event, 'peso1')">
                <span>kg</span>
                <input type="text" v-model="peso2" @input="update($event, 'peso2')">
                <span>kg</span>
            </div>
        </div>
        <div class="mediciones" style="margin-bottom:1rem;">
            <h2>TALLA</h2>
            <div class="medicion no-gap">
                <input type="text" v-model="talla1" @input="update($event, 'talla1')">
                <span>cm</span>
                <input type="text" v-model="talla2" @input="update($event, 'talla2')">
                <span>cm</span>
            </div>
        </div>

        <h2>
            Escala de Glasgow
        </h2>

        <span>Score <input style="border-bottom: black solid 1px; width: 80px;" @input="update($event, 'score')" type="text" v-model="score"> /15</span>
        <resumen-examenes class="mt-10" :seleccionadas="pielYMucosa" @change="update($event, 'pielYMucosa'); pielYMucosa=$event;" titulo="Piel y Mucosa" :opciones="['Normal', 'Rubicundez', 'Húmeda', 'Ictericia', 'Sudoroso', 'Erupción', 'Palidez', 'Lesiones', 'Cianosis']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="cabezaYCuello" @change="update($event, 'cabezaYCuello'); cabezaYCuello=$event;" titulo="Cabeza y Cuello" :opciones="['Normal']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="respiratorio" @change="update($event, 'respiratorio'); respiratorio=$event;" titulo="Respiratorio" :opciones="['Normal', 'Sibilancias', 'Paro/Apnea', 'Roncus', 'Polipnea', 'Hemoptisis', 'Bradipnea', 'Matidez', 'Irregular', 'Neumotorax', 'Paradójica', 'Hemotorax', 'Músc.Accesorios', 'Broncoaspiración', 'Estridor']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="cardiovascular" @change="update($event, 'cardiovascular'); cardiovascular=$event;" titulo="Cardiovascular" :opciones="['Normal', 'Galope', 'Paro', 'Soplo', 'Arritmia', 'Frote pericardico', 'Taquicardia', 'Ingurgicación yugular', 'Bradicardia']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="ecg" @change="update($event, 'ecg'); ecg=$event;" titulo="ECG" :opciones="['No se realiza', 'Eje', 'Normal', 'Hipert Aur', 'Frecuencia', 'Hipert Ventr', 'Ritmo sinusal', 'Bloquia -v', 'Taquic', 'Blog Rama', 'Bradic', 'Isquemia', 'Extrasístoles', 'Infarto', 'Aleteoaur', 'Fibr Auricular', 'Fib Ventr']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="abdomen" @change="update($event, 'abdomen'); abdomen=$event;" titulo="Abdomen" :opciones="['Normal', 'Murphy', 'Dolor Global', 'Organomegalias', 'Dolor focal', 'Masa', 'Silencioso', 'Masa Pulsante', 'Ruidos Aum.', 'Ascitis', 'Distendido', 'Soplo', 'Defendido', 'Eventracion','Peritonismo', 'Hernia', 'Blumberg', 'Hematemesis', 'Enterorragia']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="guObstetricio" @change="update($event, 'guObstetricio'); guObstetricio=$event;" titulo="G.U y Obstetricio" :opciones="['No se realiza', 'Dolor', 'Normal', 'Sangrado genital', 'Globo Ves.', 'Aborto en curso', 'Hematuria', 'Contracciones', 'Oliguria', 'Parto en curso', 'Poliuria', 'Prolapso', 'Anuria', 'Silencio Fetal', 'Sonda']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="psiquiatricos" @change="update($event, 'psiquiatricos'); psiquiatricos=$event;" titulo="Psiquiatricos" :opciones="['Normal', 'Delirante', 'Depresivo', 'Drogadicción', 'Ansioso', 'Intoxicación', 'Agresivo', 'Intento Suicidio', 'Indiferente']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="osteomuscular" @change="update($event, 'osteomuscular'); osteomuscular=$event;" titulo="Osteomuscular" :opciones="['Normal']">

        </resumen-examenes>
        <resumen-examenes class="mt-10" :seleccionadas="neurologico" @change="update($event, 'neurologico'); neurologico=$event;" titulo="Neurológico" :opciones="['Normal']">

        </resumen-examenes>
        <v-btn block elevation="2" color="#4451b4" style="color: white;" class="mb-3 mt-10" @click="$router.push({name: 'DiagnosticoTratamiento'})">
            SIGUIENTE
        </v-btn>
        <v-btn block elevation="2" color="#d6d8d7" @click="$router.push({name: 'ResumenConsulta'})">
            ATRÁS
        </v-btn>
    </div>
    
</v-app>
</template>

<script>
import {
    mapState
} from "vuex";
// import sweetAlert from "@/modules/sweetAlert";
import currentServiceModule from "@/modules/currentService";
import ResumenExamenes from "@/components/consulta/ResumenExamenes.vue";
export default {
    name: "DatosPaciente",
    computed: mapState({
        currentService: (state) => state.currentService,
        historiasClinicas: (state) => state.historiasClinicas,
    }),
    components: {
        ResumenExamenes
    },
    data: () => ({
        ta1: '',
        ta2: '',
        pulso1: '',
        pulso2: '',
        temperatura1: '',
        temperatura2: '',
        resp1: '',
        resp2: '',
        sat1: '',
        sat2: '',
        glucometria1: '',
        glucometria2: '',
        peso1: '',
        peso2: '',
        talla1: '',
        talla2: '',
        hora1: '',
        hora2: '',
        pielYMucosa: [],
        cabezaYCuello: [],
        respiratorio: [],
        cardiovascular: [],
        ecg: [],
        abdomen: [],
        guObstetricio: [],
        psiquiatricos: [],
        neurologico: [],
        osteomuscular: [],
        score: ''
    }),
    created() {
        const serviceData = this.historiasClinicas[this.currentService];
        this.hora1 = serviceData.hora1 ?? '';
        this.hora2 = serviceData.hora2 ?? '';
        this.ta1 = serviceData.ta1 ?? '';
        this.ta2 = serviceData.ta2 ?? '';
        this.pulso1 = serviceData.pulso1 ?? '';
        this.pulso2 = serviceData.pulso2 ?? '';
        this.temperatura1 = serviceData.temperatura1 ?? '';
        this.temperatura2 = serviceData.temperatura2 ?? '';
        this.resp1 = serviceData.resp1 ?? '';
        this.resp2 = serviceData.resp2 ?? '';
        this.sat1 = serviceData.sat1 ?? '';
        this.sat2 = serviceData.sat2 ?? '';
        this.glucometria1 = serviceData.glucometria1 ?? '';
        this.glucometria2 = serviceData.glucometria2 ?? '';
        this.peso1 = serviceData.peso1 ?? '';
        this.peso2 = serviceData.peso2 ?? '';
        this.talla1 = serviceData.talla1 ?? '';
        this.talla2 = serviceData.talla2 ?? '';
        this.pielYMucosa = serviceData.pielYMucosa ?? [];
        this.cabezaYCuello = serviceData.cabezaYCuello ?? [];
        this.respiratorio = serviceData.respiratorio ?? [];
        this.cardiovascular = serviceData.cardiovascular ?? [];
        this.ecg = serviceData.ecg ?? [];
        this.abdomen = serviceData.abdomen ?? [];
        this.guObstetricio = serviceData.guObstetricio ?? [];
        this.psiquiatricos = serviceData.psiquiatricos ?? [];
        this.neurologico = serviceData.neurologico ?? [];
        this.osteomuscular = serviceData.osteomuscular ?? [];
        this.score = serviceData.score ?? '';
    },
    methods: {
        update(event, key) {
            this.$store.commit("setKeyToHistory", {
                consecutivo: this.currentService,
                key,
                value: event.target ? event.target.value : event,
            });
        },
        async setHora(key) {
            const hora = await currentServiceModule.getHourServer();
            const horaActualizar = hora.split(' ')[1];
            this[key] = horaActualizar;
            this.update(horaActualizar, key);
        }
    }
};
</script>

<style lang="scss" scoped>
.container-mediciones {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    h1 {
        font-size: 1.5rem;
    }
    .mediciones{
        display: flex;
        align-items: center;
        h2 {
            width: 34%;
            font-size: 1rem;
        }
        .medicion {
            display: flex;
            gap: 1rem;
            width: 66%;
            input {
                border-bottom: black solid 1px;
                width: 50%;
            }
            .hora {
                width: 138px;
                background: #80808070;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .no-gap {
            gap: 0;
            span {
                margin-left: 0.1rem;
                margin-right: 0.1rem;
            }
        }
    }
}
</style>
